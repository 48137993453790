import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Collapsible from 'react-collapsible';

import Loading from '../../shared/Loading';
import previous from '../../../images/previous.png';
import chickenTreatsStatic from '../../../images/chicken_treats_static.png';
import petcoAdd from '../../../images/petco_add.jpg';
import pupfordFooterImage from '../../../images/pupford-footer-image.jpg';
import chevronDownIcon from '../../../images/chevron-down.svg';

import './CourseModule.scss';

function CourseModule({ courses, partnerModuleProducts }) {
  const params = useParams();
  const navigate = useNavigate();
  const [module, setModule] = useState(null);
  const [moduleIndex, setModuleIndex] = useState();
  const [modulesLength, setModulesLength] = useState(0);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const handleSetCourse = async (foundCourse, index) => {
      const courseId = foundCourse.id;
      const moduleId = foundCourse.modules[index].id;

      const module = await handleModule(foundCourse.modules[index]);

      if (partnerModuleProducts[courseId] && partnerModuleProducts[courseId][moduleId] && Array.isArray(partnerModuleProducts[courseId][moduleId])) {
        setProducts(partnerModuleProducts[courseId][moduleId]);
      } else {
        setProducts([]);
      }

      setModulesLength(foundCourse.modules.length);
      setModule(module);
    };

    window.scrollTo(0, 0);

    if (courses.length && params.course) {
      const courseParamArr = params.course.split('-');
      const index = +courseParamArr[courseParamArr.length - 1];
      setModuleIndex(index);

      courseParamArr.pop();

      const pathName = courseParamArr.join('-');
      const foundCourse = courses.find(c => c.pathName === pathName);

      if (foundCourse && foundCourse.modules[index]) {
        handleSetCourse(foundCourse, index);
      } else {
        navigate(`/${params.partner}`);
      }
    }
  }, [courses, params]);

  const getVimeoEmbed = async (videoUrl) => {
    try {
      const videoUrlArr = videoUrl.split('/');
      let videoId;

      if (videoUrlArr[5] && !isNaN(parseInt(videoUrlArr[5]))) {
        videoId = videoUrlArr[5];
      } else {
        return '';
      }

      const result = await axios.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`);

      if (!result || !result.data || !result.data.html) {
        return '';
      }

      const iframeDiv = document.createElement('div');
      iframeDiv.innerHTML = result.data.html;

      const iframe = iframeDiv.firstChild;
      iframe.height = '100%';
      iframe.width = '100%';

      return iframe.outerHTML;
    } catch (e) {
      console.log('error', e);
      return '';
    }
  };

  const handleModule = async (module) => {
    const updatedModule = {
      ...module,
    };

    const updatedSections = [];

    for (let i = 0; i < updatedModule.sections.length; i++) {
      const updatedSection = {
        ...updatedModule.sections[i],
      };

      if (updatedSection.entries && Array.isArray(updatedSection.entries)) {
        const updatedEntries = [];

        for (let j = 0; j < updatedSection.entries.length; j++) {
          const updatedEntry = {
            ...updatedSection.entries[j],
          };

          if (updatedEntry.type === 'video' && updatedEntry.value.includes('vimeo')) {
            updatedEntry.vimeoEmbed = await getVimeoEmbed(updatedEntry.value);
          }

          if (updatedEntry.entries && Array.isArray(updatedEntry.entries)) {
            const updatedNestedEntries = [];

            for (let k = 0; k < updatedEntry.entries.length; k++) {
              const updatedNestedEntry = {
                ...updatedEntry.entries[k],
              };

              if (updatedNestedEntry.type === 'video' && updatedNestedEntry.value.includes('vimeo')) {
                updatedNestedEntry.vimeoEmbed = await getVimeoEmbed(updatedNestedEntry.value);
              }

              updatedNestedEntries.push(updatedNestedEntry);
            }
            
            updatedEntry.entries = updatedNestedEntries;
          }

          updatedEntries.push(updatedEntry);
        }

        updatedSection.entries = updatedEntries;
      }

      updatedSections.push(updatedSection);
    }

    updatedModule.sections = updatedModule.sections.map(section => {
      const updatedSection = {
        ...section,
      };

      if (section.entries && Array.isArray(section.entries)) {
        updatedSection.entries = updatedSection.entries.map(entry => {
          return {
            ...entry,
          };
        });
      }

      return updatedSection;
    });

    updatedModule.sections = updatedSections;

    return updatedModule;
  };

  const openPDF = (value) => {
    window.open(value, '_blank')
  };

  const renderSectionItem = (s, i, j, k) => {
    if (s.type === 'title') {
      return (
        <h2 style={{fontWeight: 'bold', fontSize: 18}}>
          {s.value}
        </h2>
      );
    } else if (s.type === 'subtitle') {
      return (
        <h3 style={{fontWeight: 'bold', fontSize: 15, color: 'rgba(0, 0, 0, .6)'}}>
          {s.value}
        </h3>
      );
    } else if (s.type === 'paragraph') {
      return (
        <p className="paragraph">
          {s.value}
        </p>
      );
    } else if (s.type === 'pdf') {
      return (
        <button
          onClick={() => { openPDF(s.value) }}
        >Download PDF</button>
      );
    } else if (s.type === 'video') {
      if (s.vimeoEmbed) {
        return (
          <div className="video-container">
            <div className="video-inner" dangerouslySetInnerHTML={{__html: s.vimeoEmbed}}>
            </div>
          </div>
        );
      }

      return (
        <div className="video-container">
          <div className="video-inner">
            <video width="100%" height="100%" controls poster={s.thumbnail}>
              <source src={s.value} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      );
    } else if (s.type === 'youtube') {
      return (
        <div className="video-container">
          <div className="video-inner">
            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${s.value}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
      );
    } else if (s.type === 'image') {
      return <img src={s.value} width="100%" alt=""></img>;
    } else if (s.type === 'quiz') {
      return (
        <iframe title={`quiz-${i}-${j}`} src={s.value} frameBorder="0" width="100%" height="1000px" />
      );
    } else if (s.type === 'products') {
      if (!products.length) {
        return null;
      } else {
        return (
          <>
            <div className="section-title">Combine with</div>

            {products.map((product, i) => {
              if (product.enableLink && product.linkUrl) {
                return (
                  <a
                    key={`product-${i}`}
                    className="moduleLink boxShadow"
                    href={product.linkUrl}
                    target="_blank"
                  >
                    <div className="moduleImageWrapper containImage product-image-container">
                      <img
                        src={product.imageUrl}
                      />
                    </div>
                    <div className="moduleLinkText product-yellow">
                      <h3>{product.name}</h3>
                    </div>
                  </a>
                );
              }

              return (
                <div
                  key={`product-${i}`}
                  className="moduleLink boxShadow"
                >
                  <div className="moduleImageWrapper containImage product-image-container">
                    <img
                      src={product.imageUrl}
                    />
                  </div>
                  <div className="moduleLinkText product-yellow">
                    <h3>{product.name}</h3>
                  </div>
                </div>
              );
            })}

            {/* <div className="products-container">
              {products.map((row, i) => {
                return (
                  <div className="row" key={`product-row-${i}`}>
                    {row.map((product, j) => {
                      return (
                        <div key={`product-${j}`} className="product-card">
                          {!product.name ? null :
                            <a onClick={() => {
                              // setSelectedProduct({
                              //   id: product.id,
                              //   name: product.name,
                              //   image: {
                              //     url: product.image,
                              //   },
                              // });
                              // setProductModalOpen(true);
                            }}>
                              <div className="product-container">
                                <div className="product-image" style={{backgroundImage: `url(${product.image})`}} />
                                <div className="product-text">
                                  <h3>{product.name}</h3>
                                </div>
                              </div>
                            </a>
                          }
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div> */}
            {/* TODO - make real adds a link - build in needed URLS as part of retail onboarding */}
            {/* <img className="retail-add" src={petcoAdd} /> */}
          </>
        );
      }
    } else if ( s.entries ) {
      return (
        <>
          {s.entries.length === 1 && s.entries[0].type === 'pdf' ?
            <button className="secondaryBtn" onClick={() => { openPDF(s.entries[0].value) }}>
              Download Course PDF
            </button> :
            <Collapsible trigger={<span><div>{s.sectionTitle}</div><img src={chevronDownIcon} alt="" /></span>} transitionTime={150}>
            {s.entries.map((s, k) => {
              return (
                <div key={`nested-detail-section-${i}-${k}`} style={{marginTop: 6, marginBottom: 6}}>
                  {renderSectionItem(s, i, j, k)}
                </div>
              );
            })}
            </Collapsible>
          }
        </>
      );
    }
  };

  return (
    <>
      <div className="container CourseModule">
        <div className="content-max-width">
          {!module ? <Loading/> :
            <>
              <div className="back-link-container">
                <Link to={`/${params.partner}`}>
                  <img className="previous-icon" src={previous}></img>
                  <p>{courses[0].name}</p>
                </Link>
              </div>

              <div className="header">
                <h1>{module.title}</h1>
                <h2>{module.description}</h2>
              </div>

              <hr className="solid" />

              <div className="module-container">
                {module.sections.map((s, i) => {
                  return (
                    <div key={`main-section-${i}`} className="main-section">
                      <div>
                        <div className="main-section-inner">
                          <h2>{s.sectionTitle}</h2>

                          {s.entries.map((s, j) => {
                            return (
                              <div key={`detail-section-${i}-${j}`}>
                                {renderSectionItem(s, i, j)}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <hr className="solid-wide" />
                    </div>
                  );
                })}
              </div>
              {moduleIndex === modulesLength - 1 ? null :
                <>
                  <div className="next-module-header">
                    <p>Up Next</p>
                    <p>{moduleIndex + 1} of {modulesLength}</p>
                  </div>
                  <Link
                    key={`featured-course-module-next`}
                    className="moduleLink boxShadow"
                    to={`/${params.partner}/${courses[0].pathName}-${moduleIndex + 1}`}
                  >
                    <div className="moduleImageWrapper containImage">
                      <img
                        src={courses[0].modules[moduleIndex + 1].backgroundImage}
                      />
                    </div>
                    <div className="moduleLinkText">
                      <h3>{courses[0].modules[moduleIndex + 1].title}</h3>
                      <p>{courses[0].modules[moduleIndex + 1].description}</p>
                    </div>
                  </Link>
                </>
              }
            </>
          }
        </div>
      </div>
      <img className="pupford-footer-image" src={pupfordFooterImage} />
    </>
  );
}

export default CourseModule;
